import { Router, UrlTree } from '@angular/router';
import { Observable, zip } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { PermissionService } from '../+state/permission.service';

export abstract class PermissionBasedAuthGuardService {
  protected abstract activated: boolean;
  protected abstract failureUrl: string;
  protected abstract matchPolicy: 'any' | 'all';
  protected abstract permissionService: PermissionService;
  protected abstract requiredPermissions: string[];
  protected abstract router: Router;

  canActivate(): Observable<boolean | UrlTree> | boolean | UrlTree {
    if (!this.activated) {
      return this.getFailureValue();
    }

    if (this.requiredPermissions.length === 0) {
      return this.getSuccessValue();
    }

    this.permissionService.initializeCurrentUserPermissions();

    return this.permissionsSatisfied().pipe(
      map((permissionsSatisfied) =>
        permissionsSatisfied ? this.getSuccessValue() : this.getFailureValue(),
      ),
      first(),
    );
  }

  canLoad(): boolean | UrlTree | Observable<boolean | UrlTree> {
    return this.canActivate();
  }

  getSuccessValue(): boolean | UrlTree {
    return true;
  }

  getFailureValue(): boolean | UrlTree {
    return this.router.parseUrl(this.failureUrl);
  }

  permissionsSatisfied(): Observable<boolean> {
    return zip(
      ...this.requiredPermissions.map((permission) =>
        this.permissionService.hasCurrentPermission(permission).pipe(first()),
      ),
    ).pipe(
      map((permissionsSatisfied) => {
        if (this.matchPolicy === 'all') {
          return permissionsSatisfied.every((permissionSatisfied) => permissionSatisfied);
        }
        // If 'any'
        return permissionsSatisfied.some((permissionSatisfied) => permissionSatisfied);
      }),
    );
  }
}
